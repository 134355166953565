@tailwind base;
@tailwind components;
@tailwind utilities;

input,
input:focus {
  outline: none;
}
a {
  color: inherit;
}

.spinner {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #b42362;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
textarea {
  resize: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font//Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font//Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./font//Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

.bounce {
  animation: bouncing 500ms alternate infinite;
}
@keyframes bouncing {
  from {
    transform: translateX(5px);
  }
  to {
    transform: translate(0px);
  }
}

.slide-in {
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;
}
@keyframes slide {
  from {
    left: -100px;
  }
  to {
    left: 0px;
  }
}
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 0.5;
  background-color: #00000015;
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 0.5;
  background-color: #13131374;
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 7px;
}
